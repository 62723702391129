import dayjs from 'dayjs';

const formatTime = (timeString) => {
    if (!timeString) return 0;
    const date = new Date(timeString);
    const options = { hour: 'numeric', minute: '2-digit' };
    return date.toLocaleTimeString('en-US', options);
};

const convertToMinutes = (timeString) => {
    const date = new Date(timeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return hours * 60 + minutes;
};

const convertToHoursAndMinuteFromSecond = (seconds) => {
    if (!seconds) return 0;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
};

const normalizeDate = (originalDateString) => {
    if (!originalDateString) return null;
    const originalDate = new Date(originalDateString);

    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return originalDate.toLocaleDateString('en-US', options);
};

const normalizeIso8601Date = (dateObj) => {
    if (!dateObj) return dayjs().format('YYYY-MM-DDTHH:mm:ss');
    return dayjs(dateObj).format('YYYY-MM-DDTHH:mm:ss');
};

const normalizeIso8601DateOnly = (dateObj) => {
    if (!dateObj) return dayjs().format('YYYY-MM-DD');
    return dayjs(dateObj).format('YYYY-MM-DD');
};

const getCurrentMonthDate = (date) => {
    // const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const startOfMonth = date
        ? dayjs(date).startOf('month').format('YYYY-MM-DD')
        : dayjs().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = date
        ? dayjs(date).endOf('month').format('YYYY-MM-DD')
        : dayjs().endOf('month').format('YYYY-MM-DD');
    return { startOfMonth, endOfMonth };
};

function calculateSecondsToHoursAndMinutes(seconds) {
    if (!seconds) return '00:00';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // Add leading zeros
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}`;
}

function getCurrentStartAndEndOfWeek() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the start of the week (Monday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(
        currentDate.getDate() -
            currentDate.getDay() +
            (currentDate.getDay() === 0 ? -6 : 1)
    );

    // Calculate the end of the week (Sunday)
    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    // Format the dates in ISO 8601 format
    const formattedStartOfWeek = startOfWeek.toISOString().split('T')[0];
    const formattedEndOfWeek = endOfWeek.toISOString().split('T')[0];

    // Return an object with startOfWeek and endOfWeek
    return {
        startOfWeek: formattedStartOfWeek + 'T00:00:00',
        endOfWeek: formattedEndOfWeek + 'T23:59:59'
    };
}

const formatDateToDDMMM = (date) => {
    return dayjs(date).format('DD MMM');
};

function sickLeaveFlaggedChecker(day, { categoryName }) {
    if (
        (day === 'Mon' || day === 'Fri') &&
        categoryName.toLowerCase() === 'sick'
    )
        return 'primary.main';
    return 'secondary.main';
}

function modifyWeek({ startOfWeek, endOfWeek }, next) {
    // Convert strings to Date objects
    const startDate = new Date(startOfWeek);
    const endDate = new Date(endOfWeek);

    // Determine the adjustment value based on the 'next' flag
    const adjustment = next ? 7 : -7;

    // Add or subtract the adjustment to both start and end dates
    startDate.setDate(startDate.getDate() + adjustment);
    endDate.setDate(endDate.getDate() + adjustment);

    // Adjust for local timezone offset
    const startOffset = startDate.getTimezoneOffset();
    const endOffset = endDate.getTimezoneOffset();

    startDate.setMinutes(startDate.getMinutes() - startOffset);
    endDate.setMinutes(endDate.getMinutes() - endOffset);

    // Format the result dates to the desired format
    const formattedStartDate = startDate.toISOString().replace(/\.000Z$/, '');
    const formattedEndDate = endDate.toISOString().replace(/\.000Z$/, '');

    return {
        startOfWeek: formattedStartDate,
        endOfWeek: formattedEndDate
    };
}

function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) && date instanceof Date;
}

const getWeekDays = () => [
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '3' },
    { label: 'Thursday', value: '4' },
    { label: 'Friday', value: '5' },
    { label: 'Saturday', value: '6' },
    { label: 'Sunday', value: '0' }
];

export {
    formatTime,
    convertToMinutes,
    convertToHoursAndMinuteFromSecond,
    normalizeDate,
    calculateSecondsToHoursAndMinutes,
    normalizeIso8601Date,
    getCurrentStartAndEndOfWeek,
    formatDateToDDMMM,
    modifyWeek,
    isValidDate,
    normalizeIso8601DateOnly,
    getCurrentMonthDate,
    getWeekDays,
    sickLeaveFlaggedChecker
};
