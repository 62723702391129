import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import {
    Box,
    IconButton,
    List,
    ListItem,
    TableBody,
    TableRow,
    Typography,
    styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

import { sickLeaveFlaggedChecker } from '@libs/dateTime';

import ContentContainer from '@libComponents/ContentContainer';
import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import DialogWrapper from '@libComponents/Dialog';
import { Button } from '@libComponents';
import PaginationContainer from '@libComponents/table/PaginationWrapper';

import {
    datasetLoadingSelector,
    entitySelector,
    createFetchPaginatedDataset
} from '@libs/datasets';

import { theme } from 'app/Theme';
import { EMPLOYEE_APPLIED_LEAVES_FOR_USERS } from './redux.datasets';
import { DOMAIN_NAME, ROWS_PER_PAGE } from './common';
import ActionList from 'leaveAndHolidays/components/ActionList';

const propTypes = {};

const defaultProps = {
    entity: EMPLOYEE_APPLIED_LEAVES_FOR_USERS,
    domain: DOMAIN_NAME,
    loading: true
};

const StyledBox = styled(Box)(({ theme: { palette, typography, shape } }) => ({
    width: '100%',
    overflow: 'auto',
    '& table': {
        tableLayout: 'fixed'
    }
}));

const StyledRejectLabel = styled('span')(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        background: palette.rejected.light,
        color: palette.rejected.main,
        padding: spacing(0.5) + ' ' + spacing(2),
        borderRadius: shape.borderRadius
    })
);

const StyledApproveLabel = styled('span')(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        background: palette.approved.light,
        color: palette.approved.main,
        padding: spacing(0.5) + ' ' + spacing(2),
        borderRadius: shape.borderRadius
    })
);

const StyledCancelButton = styled('span')(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        background: palette.cancelled.light,
        color: palette.cancelled.main,
        padding: spacing(0.5) + ' ' + spacing(2),
        borderRadius: shape.borderRadius
    })
);

const StyledPendingButton = styled('span')(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        background: palette.pending.light,
        color: palette.pending.main,
        padding: spacing(0.5) + ' ' + spacing(2),
        borderRadius: shape.borderRadius
    })
);

const StyledMenuBUTTON = styled(Button)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        cursor: 'pointer',
        justifyContent: 'flex-start',
        pl: 3,
        width: 'auto'
    })
);

const StyledListItem = styled(ListItem)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        borderBottom: '1px solid',
        borderColor: palette.divider
    })
);

const TABLE_DATA = {
    'employeeEmail': 'Employee Email',
    'categoryName': 'Leave Type',
    'totalNumberOfDays': 'Number Of Days',
    'startDate': 'Start Date',
    'endDate': 'End Date',
    'status': 'Status'
};

const totalRowsSelector = createCachedSelector(entitySelector, (resp) =>
    resp.total ? parseInt(resp.total) : 0
)((domain, entity) => `@data-${domain}-${entity}`);

const rowsToShowInDetails = {
    'employeeEmail': {
        label: 'Employee Email',
        type: 'string'
    },
    'status': {
        label: 'Status',
        type: 'string'
    },
    'category': {
        label: 'Category',
        type: 'object',
        key: 'name'
    },
    'totalNumberOfDays': {
        label: 'Number Of Days',
        type: 'string'
    },
    'startDate': {
        label: 'Start Date',
        type: 'date'
    },
    'endDate': {
        label: 'End Date',
        type: 'date'
    },
    'employeesToNotify': {
        label: 'Employees To Notify',
        type: 'array'
    },
    'isEmergency': {
        label: 'Is Emergency',
        type: 'boolean'
    },
    'createdAt': {
        label: 'Created date',
        type: 'date'
    },
    'updatedAt': {
        label: 'Updated date',
        type: 'date'
    },
    'updatedBy': {
        label: 'Updated By',
        type: 'string'
    },
    'requiredDocuments': {
        label: 'Documents',
        type: 'file'
    },
    'reason': {
        label: 'Reason',
        type: 'string'
    },
    'comment': {
        label: 'Admin Comment',
        type: 'string'
    }
};

const dataSelector = createSelector(
    (state) =>
        entitySelector(state, DOMAIN_NAME, EMPLOYEE_APPLIED_LEAVES_FOR_USERS),
    (resp) => (resp && resp.data ? resp.data : [])
);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    totalRows: totalRowsSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: ({
        offset = '',
        limit = ROWS_PER_PAGE,
        shouldAppend = false,
        filter
    }) =>
        dispatch(
            createFetchPaginatedDataset({
                entity: entity,
                domain: DOMAIN_NAME
            })({
                limit,
                offset,
                shouldAppend,
                'sort-descending': true,
                filterKey: filter
            })
        )
});

const AppliedLeaves = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, totalRows = 0, ...props }) => {
    const {
        state: { email }
    } = useLocation();

    useEffect(() => {
        fetchDataset({
            offset: '',
            filter: {
                searchTerm: email
            }
        });
    }, []);

    const [openReject, setOpenReject] = React.useState(false);
    const [rejectPayload, setRejectPayload] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [payload, setSelectedPayload] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        fetchDataset({
            offset: (newPage - 1) * ROWS_PER_PAGE,
            filter: {
                searchTerm: email
            }
        });
    };

    const viewLeaveDetails = (row) => {
        setOpen(true);
        setSelectedPayload(row);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRejectClose = () => {
        setOpenReject(false);
        setRejectPayload(null);
    };

    if (!data) return null;

    return (
        <ContentContainer
            title={`All Leaves of ${email}`}
            headerBg={theme.palette.background.mainHeader}
        >
            <StyledBox>
                <TableContainer>
                    <TableHeaderContainer>
                        {Object.values(TABLE_DATA).map((th, i) => (
                            <TableCellContainer
                                sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                                typography='caption'
                                key={th}
                            >
                                {th}
                            </TableCellContainer>
                        ))}
                        <TableCellContainer
                            align='right'
                            sx={{
                                pr: 16.5,
                                fontWeight: 'bold',
                                textAlign: 'right',
                                pr: 4
                            }}
                        >
                            Action
                        </TableCellContainer>
                    </TableHeaderContainer>
                    <TableBody>
                        {data.map((row, i) => (
                            <TableRow key={i}>
                                {Object.keys(TABLE_DATA).map((key, index) => {
                                    switch (key) {
                                        case 'startDate':
                                            return (
                                                <TableCellContainer
                                                    key={key}
                                                    sx={{
                                                        color: sickLeaveFlaggedChecker(
                                                            dayjs(
                                                                row[key]
                                                            ).format('ddd'),
                                                            row
                                                        )
                                                    }}
                                                >
                                                    {dayjs(row[key]).format(
                                                        'YYYY-MM-DD (ddd)'
                                                    )}
                                                </TableCellContainer>
                                            );
                                        case 'endDate':
                                            return (
                                                <TableCellContainer
                                                    key={key}
                                                    sx={{
                                                        color: sickLeaveFlaggedChecker(
                                                            dayjs(
                                                                row[key]
                                                            ).format('ddd'),
                                                            row
                                                        )
                                                    }}
                                                >
                                                    {dayjs(row[key]).format(
                                                        'YYYY-MM-DD (ddd)'
                                                    )}
                                                </TableCellContainer>
                                            );
                                        case 'status':
                                            return (
                                                <TableCellContainer key={key}>
                                                    {row[key].toLowerCase() ===
                                                    'approved' ? (
                                                        <StyledApproveLabel>
                                                            {row[
                                                                key
                                                            ].toUpperCase()}
                                                        </StyledApproveLabel>
                                                    ) : row[
                                                          key
                                                      ].toLowerCase() ===
                                                      'rejected' ? (
                                                        <StyledRejectLabel>
                                                            {row[
                                                                key
                                                            ].toUpperCase()}
                                                        </StyledRejectLabel>
                                                    ) : row[
                                                          key
                                                      ].toLowerCase() ===
                                                      'cancelled' ? (
                                                        <StyledCancelButton>
                                                            {row[
                                                                key
                                                            ].toUpperCase()}
                                                        </StyledCancelButton>
                                                    ) : (
                                                        <StyledPendingButton>
                                                            {row[
                                                                key
                                                            ].toUpperCase()}
                                                        </StyledPendingButton>
                                                    )}
                                                </TableCellContainer>
                                            );
                                        default:
                                            return (
                                                <TableCellContainer
                                                    key={index}
                                                    sx={{
                                                        pl: index === 0 ? 4 : 2
                                                    }}
                                                >
                                                    {row[key]}
                                                </TableCellContainer>
                                            );
                                    }
                                })}
                                <TableCellContainer align='right'>
                                    <ActionList row={row}>
                                        <StyledMenuBUTTON
                                            variant='outlined'
                                            type='button'
                                            onClick={() => {
                                                viewLeaveDetails(row);
                                            }}
                                            size='small'
                                            color='primary'
                                            startIcon={
                                                <InfoIcon color='primary' />
                                            }
                                        >
                                            Details
                                        </StyledMenuBUTTON>
                                    </ActionList>
                                </TableCellContainer>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <PaginationContainer
                        count={Math.ceil(totalRows / ROWS_PER_PAGE)}
                        onChange={handleChangePage}
                        color='primary'
                    />
                </Box>
            </StyledBox>
            <DialogWrapper
                open={open}
                onClose={handleClose}
                sx={{ textAlign: 'center' }}
            >
                <Typography variant='h6' sx={{ mb: 2 }}>
                    Leave Details
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon
                        sx={{
                            color: (theme) => theme.palette.primary.main
                        }}
                    />
                </IconButton>
                <Box>
                    <List dense={false} sx={{ mb: 3 }}>
                        {Object.entries(rowsToShowInDetails).map(
                            ([key, value], index) => {
                                let type = value.type;
                                switch (type) {
                                    case 'object':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>
                                                    {value.label}{' '}
                                                </Box>
                                                <Box sx={{ flex: 2 }}>
                                                    {payload[key] &&
                                                        payload[key][value.key]}
                                                </Box>
                                            </StyledListItem>
                                        );
                                    case 'array':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>
                                                    {value.label}{' '}
                                                </Box>
                                                <Box sx={{ flex: 2 }}>
                                                    {payload[key] &&
                                                        payload[key]
                                                            .map(
                                                                (item) =>
                                                                    item?.firstname +
                                                                    ' ' +
                                                                    item?.middlename +
                                                                    ' ' +
                                                                    item?.lastname
                                                            )
                                                            .join(', ')}
                                                </Box>
                                            </StyledListItem>
                                        );
                                    case 'file':
                                        return null;
                                    case 'date':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>
                                                    {value.label}{' '}
                                                </Box>
                                                <Box sx={{ flex: 2 }}>
                                                    {payload[key]
                                                        ? dayjs(
                                                              payload[key]
                                                          ).format('YYYY-MM-DD')
                                                        : ''}
                                                </Box>
                                            </StyledListItem>
                                        );
                                    case 'boolean':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>
                                                    {value.label}{' '}
                                                </Box>
                                                <Box sx={{ flex: 2 }}>
                                                    {payload[key] &&
                                                    payload[key]
                                                        ? 'Yes'
                                                        : 'No'}
                                                </Box>
                                            </StyledListItem>
                                        );
                                    default:
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>
                                                    {value.label}{' '}
                                                </Box>
                                                <Box sx={{ flex: 2 }}>
                                                    {payload[key]}
                                                </Box>
                                            </StyledListItem>
                                        );
                                }
                            }
                        )}
                    </List>
                </Box>
            </DialogWrapper>
            <DialogWrapper
                open={openReject}
                onClose={handleRejectClose}
                sx={{ textAlign: 'center' }}
            >
                <Box sx={{ 'minWidth': '600px', minHeight: '200px' }}></Box>
            </DialogWrapper>
        </ContentContainer>
    );
});

AppliedLeaves.propTypes = propTypes;
AppliedLeaves.defaultProps = defaultProps;

export default AppliedLeaves;
